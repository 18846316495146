// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-dfb-gatsby-dfb-theme-src-pages-403-js": () => import("./../../../node_modules/@dfb/gatsby-dfb-theme/src/pages/403.js" /* webpackChunkName: "component---node-modules-dfb-gatsby-dfb-theme-src-pages-403-js" */),
  "component---node-modules-dfb-gatsby-dfb-theme-src-pages-404-js": () => import("./../../../node_modules/@dfb/gatsby-dfb-theme/src/pages/404.js" /* webpackChunkName: "component---node-modules-dfb-gatsby-dfb-theme-src-pages-404-js" */),
  "component---node-modules-dfb-gatsby-dfb-theme-src-pages-preview-renderer-js": () => import("./../../../node_modules/@dfb/gatsby-dfb-theme/src/pages/preview-renderer.js" /* webpackChunkName: "component---node-modules-dfb-gatsby-dfb-theme-src-pages-preview-renderer-js" */),
  "component---node-modules-dfb-gatsby-dfb-theme-src-pages-search-js": () => import("./../../../node_modules/@dfb/gatsby-dfb-theme/src/pages/search.js" /* webpackChunkName: "component---node-modules-dfb-gatsby-dfb-theme-src-pages-search-js" */),
  "component---node-modules-dfb-gatsby-dfb-theme-src-templates-typo-3-page-js": () => import("./../../../node_modules/@dfb/gatsby-dfb-theme/src/templates/typo3-page.js" /* webpackChunkName: "component---node-modules-dfb-gatsby-dfb-theme-src-templates-typo-3-page-js" */)
}

